import React from "react";
import {
  Card,
  Spin,
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  notification
} from "antd";
import PropTypes from "prop-types";
import { AutoCompleteCity } from "components/autocomplete/autocomplete-city";
import { STATES_DATA } from "utils/static-data";
import useHttpGeo from "hooks/use-http-geo";
import Search from "antd/es/input/Search";

const FormAddress = (props) => {
  const {
    loading,
    form,
    title,
    receiver,
    note,
    card,
    fullWidth,
    compact = false,
    required = true
  } = props;

  const httpGeo = useHttpGeo();
  const [isOptionLoading, setIsOptionLoading] = React.useState(false);
  const onSelectedCity = (e) => {
    form.setFieldsValue({
      address: { state: e ? STATES_DATA[e.state] : null }
    });
  };

  async function getAdressInfo() {
    const { zipcode } = form.getFieldValue().address;
    try {
      const data = await httpGeo.getCep(zipcode);
      form.setFieldsValue({
        address: {
          address: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf
        }
      });
    } catch (err) {
      notification.error({
        message: err.message
      });
    }
  }
  const Address = React.useMemo(() => (
    <Row gutter={24}>
      <Col style={{ width: "100%" }}>
        <Form.Item
          name={["address", "zipcode"]}
          rules={[{ required: required, message: "Insira o CEP" }]}
          label="CEP"
          style={{ width: "100%" }}
        >
          <Search
            autoComplete="off"
            enterButton="Consultar cep"
            onSearch={getAdressInfo}
            loading={httpGeo.loading}
          />
        </Form.Item>
      </Col>
      <Col {...(fullWidth ? { xl: 4, lg: 12 } : { xl: 18, lg: 12, md: 24 })}>
        <Form.Item
          label="Cidade"
          hasFeedback
          name={["address", "city"]}
          rules={[{ required: required, message: "Insira a cidade" }]}
        >
          <AutoCompleteCity
            setIsOptionLoading={setIsOptionLoading}
            onSelectedItem={onSelectedCity}
          />
        </Form.Item>
      </Col>
      <Col {...(fullWidth ? { xl: 2 } : { xl: 6, lg: 12, md: 24 })}>
        <Form.Item label="Estado" name={["address", "state"]}>
          <Input disabled autoComplete="off" />
        </Form.Item>
      </Col>
      <Col {...(fullWidth ? { xl: 5 } : { xl: 17, lg: 12, md: 24 })}>
        <Form.Item
          label="Rua"
          hasFeedback
          name={["address", "address"]}
          rules={[{ required: required, message: "Insira a rua" }]}
        >
          <Input autoComplete="off" maxLength={40} />
        </Form.Item>
      </Col>
      <Col {...(fullWidth ? { xl: 2 } : { xl: 7, lg: 12, md: 24 })}>
        <Form.Item
          label="Número"
          hasFeedback
          name={["address", "number"]}
          rules={[{ required: required, message: "Insira o número" }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
      </Col>
      <Col {...(fullWidth ? { xl: 4 } : { xl: 12, lg: 12, md: 24 })}>
        <Form.Item label="Complemento" name={["address", "complement"]}>
          <Input autoComplete="off" maxLength={40} />
        </Form.Item>
      </Col>
      <Col {...(fullWidth ? { xl: 4 } : { xl: 12, lg: 12, md: 24 })}>
        <Form.Item
          label="Bairro"
          hasFeedback
          name={["address", "neighborhood"]}
          rules={[{ required: required, message: "Insira o bairro" }]}
        >
          <Input autoComplete="off" maxLength={40} />
        </Form.Item>
      </Col>
      {receiver && (
        <Col {...(fullWidth ? {} : { md: 24 })}>
          <Form.Item
            label="Destinatário"
            name={["receiver"]}
            hasFeedback
            rules={[{ required: required, message: "Insira o destinatário" }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
      )}
      {note && (
        <Col {...(fullWidth ? {} : { md: 24 })}>
          <Form.Item
            label="Observação"
            hasFeedback
            name={["note"]}
            rules={[{ required: required, message: "Insira uma observação" }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
      )}
    </Row>
  ));

  if (card) {
    return (
      <Card title={title} style={compact ? { height: "100%" } : {}}>
        <Spin spinning={loading || isOptionLoading}>{Address}</Spin>
      </Card>
    );
  } else {
    return Address;
  }
};

FormAddress.defaultProps = {
  loading: false,
  title: "Endereço",
  receiver: false,
  note: false,
  card: true,
  fullWidth: false,
  required: true
};

FormAddress.propTypes = {
  loading: PropTypes.bool,
  form: PropTypes.any.isRequired,
  title: PropTypes.string,
  receiver: PropTypes.bool,
  note: PropTypes.bool,
  card: PropTypes.bool,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool
};

export { FormAddress };
